@charset "utf-8";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
}
body {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
  background-color: #fff;
  color: #767676;
  overflow: hidden;
}
/*-----------------------------------------------------------*/
/*HEADER
/*-----------------------------------------------------------*/

.logo-wrap{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  line-height: 65px;
  
  float:left; 
}

.logo-text{
  z-index: -1;
  visibility: hidden;
}

.logo-white{
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../public/img/logo/white.svg");
  background-size: 75px 46px;
}

/*-----------------------------------------------------------*/
/*HERO
/*-----------------------------------------------------------*/
.ew_hero{
  width: 100%;
  height: 100vh;
  float: left;
  clear: both;
  overflow: hidden;
}
.ew_hero .background {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
}
.ew_hero::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.ew_hero .image {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: -1;
  transform: scale(1);
  opacity: 1;
}
.ew_hero .content {
  position: absolute;
  padding: 50px;
  z-index: 2;
}
@media screen and (max-width: 600px) {
  .ew_hero .content {
    position: absolute;
    padding: 80px;
    z-index: 2;
  }
}

/*-----------------------------------------------------------*/
/*SVG SHAPE
/*-----------------------------------------------------------*/
.my_shape{
	position: absolute;
	width: 100%;
	left: 0px;
	bottom: -11px;
	z-index: 5;
}

.my_shape .svg{
	width: 100%;
	height: 100%;
}
.my_shape svg{
	width: 100%;
	height: 100%;
	color: #fff;
}
@media screen and (max-width: 600px) {
  .my_shape{
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -2px;
    z-index: 5;
  }
  
}
.my_shape2{
	position: absolute;
	width: 100%;
	left: 0px;
	
	z-index: 5;
}
.my_shape2 .svg{
	width: 100%;
	height: 100%;
}

/*-----------------------------------------------------------*/
/*ABOUT/ SKILLS SVG AND ANIMATION
/*-----------------------------------------------------------*/
.avatar{
  width: 100%;
	clear: both;
	position: relative;
}
@media screen and (max-width: 600px) {
  .avatar .skills.skills.javascript, .avatar .skills.bootstrap, .avatar .skills.wordpress{
    display: none;
  }
}
.avatar .skills{
	display: inline-block;
	width: 75px;
	height: 75px;
	border-radius: 8px;
	position: absolute;
	box-shadow: 24px 0px 72px #fa5b0f;
}
.avatar .skills.javascript{
	background-color: #f7df1e;
	top: 20px;
	left: 30px;
}
.avatar .skills.wordpress{
	background-color: #fff;
	right: 70px;
	top: 80px;
}
.avatar .skills.bootstrap{
	background-color: #563e7c;
	top:280px;
	left: 180px;
}
.avatar .skills .svg{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 40px;
	height: 40px;
}
.avatar .skills.skills.javascript .svg{
  filter: brightness(0);
}
.avatar .skills.wordpress .svg{
  filter: invert(35%) sepia(63%) saturate(557%) hue-rotate(153deg) brightness(98%) contrast(91%);
}
.avatar .skills.bootstrap .svg{
  filter:  brightness(0) invert(1);
}
.skills.seo .svg{
  width: 64px;
  height: 64px;
  filter:  brightness(0) invert(1);
}
.skills.code .svg{
  width: 64px;
  height: 64px;
  filter:  brightness(0) invert(1);
}
.skills.design .svg{
  width: 64px;
  height: 64px;
  filter:  brightness(0) invert(1);

}


.animPulse{
	animation: animPulse 3s linear 0s infinite alternate;
}
@keyframes animPulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.anim_moveLeft{
	animation: animMoveLeft 3s linear 0s infinite alternate;
}

@keyframes animMoveLeft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-20px);
  }
}

.anim_moveRight{
	animation: anim_moveRight 3s linear 0s infinite alternate;
}

@keyframes anim_moveRight {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(20px);
  }
}

.anim_moveTop{
	animation: anim_moveTop 3s linear 0s infinite alternate;
}

@keyframes anim_moveTop {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.anim_moveBottom{
	animation: anim_moveBottom 3s linear 0s infinite alternate;
}

@keyframes anim_moveBottom {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(20px);
  }
}

/*-----------------------------------------------------------*/
/*PORTFOLIO
/*-----------------------------------------------------------*/


.react-tabs__tab--selected {
  background-color: #000;
  color: #fff;
  padding: 5px;
}
.react-tabs__tab-panel--selected {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running customOne;
  animation: 800ms ease-in-out 0s normal none 1 running customOne;
}

@keyframes customOne {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}